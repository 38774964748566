.react-datepicker {
  border: 1px solid #e0e0e0; /* theme.palette.grey.300 */
  box-shadow: 0 0 16px #d5d5d5; /* theme.palette.grey.A100 */
  font-family: Avenir, Helvetica, Georgia, Arial, sans-serif; /* Imitates Undivided theme font. */
}

/* The react-datepicker-wrapper definitions are meant to mimic the underline behavior of MUI text fields. */
.react-datepicker-wrapper {
  position: relative;
}

.react-datepicker-wrapper:hover:before {
}

.react-datepicker-wrapper:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

/* This is currently inert: second class needs to be added when popper appears. */
.react-datepicker-wrapper.react-datepicker-wrapper-focused:after {
  transform: scaleX(1);
}

.react-datepicker-wrapper:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.react-datepicker__header {
  background-color: #f5f5f5; /* theme.palette.grey.100 */
  border-bottom-color: #e0e0e0; /* theme.palette.grey.300 */
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #f5f5f5; /* theme.palette.grey.100 */
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e0e0e0; /* theme.palette.grey.300 */
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #f5f5f5; /* theme.palette.grey.100 */
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #e0e0e0; /* theme.palette.grey.300 */
}

/* Meant to approximate the _load more_ button on some lists. */
.react-datepicker__today-button {
  background: #f5f5f5; /* theme.palette.grey.100 */
  border: 1px solid #e0e0e0; /* theme.palette.grey.300 */
  border-radius: 24px;
  color: #1c2e36;
  font-family: Helvetica, Avenir, Georgia, Arial, sans-serif; /* Undivided theme.typography.button */
  font-weight: normal;
  margin: 8px;
  text-transform: lowercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.react-datepicker__today-button:hover {
  background-color: rgba(28, 46, 54, 0.08);
}

.react-datepicker__today-button:active {
  background-color: rgba(28, 46, 54, 0.16);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #00c8aa; /* Undivided green */
  font-weight: bold;
}

.react-datepicker__input-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 6px 0 7px; /* Imitates Material UI Input padding. */
  position: static;
}

.react-datepicker__input-container > input::placeholder {
  color: #aaaaaa;
}

.react-datepicker__input-container > input {
  background-color: transparent;
  border: none;
  font-family: Avenir, Helvetica, Georgia, Arial, sans-serif; /* Imitates Undivided theme font. */
  margin-right: 4px;
  padding: 0;
  text-align: left; /* left align in edit mode */
}

.react-datepicker__input-container > input:focus {
  outline: 0;
}

/*
  Major restyling for the clear button: due to the original's absolute positioning,
  all four coordinates TRBL need to be reset as 'auto'.

  Base button size: 14px
*/
.react-datepicker__close-icon {
  bottom: auto;
  height: 14px;
  left: auto;
  padding: 0;
  position: static;
  right: auto;
  top: auto;
  vertical-align: auto;
  width: 14px;
}

.react-datepicker__close-icon::after {
  background-color: #d5d5d5; /* theme.palette.grey.A100 */
  bottom: auto;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  height: 14px;
  left: auto;
  line-height: 7px;
  padding-right: 3px;
  position: static;
  right: auto;
  top: auto;
  width: 14px;
}
